import { useState, useEffect } from "react"

import { TopContributor, NoData, Metadata, LoadingScreen } from "../components";
import * as articlesData from "../data/articles";

import { motion } from "framer-motion";

export function TopContributors() {

  const [contributors, setContributors] = useState([]);
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const getContributors = async () => {
      const queryParam = "";
      const res = await articlesData.getContributors(queryParam);

      if (res.code !== 200) {
        return setIsFetching(false)
      }

      let filteredContributors = res.data;
      if(res.data.length > 0) {
        filteredContributors = res.data.filter(contributor => contributor.articles.length > 0);
      }
      setContributors(filteredContributors);
      setIsFetching(false)
    };

    getContributors();
  }, [])

  return(
    // <motion.main 
    // initial={{ opacity: 1, x: -100 }} // Mulai dari luar layar sebelah kanan
    // animate={{ opacity: 1, x: 0 }} // Animasi masuk ke posisi normal
    // exit={{ opacity: 1, x: 100 }} // Animasi keluar ke luar layar sebelah kiri
    // transition={{ duration: 0.1 }} // Durasi animasi
    // >
      <div className="container d-flex flex-column" style={{margin: "5rem 0px"}}>
      <Metadata page={window.location.href} titlePage={`Top Contributors • Bipi World`} />

        <section id="top-contributors">
          <div className="section-header mb-4">
            <h3 className="section-title">Top Contributors</h3>
          </div>
          <div className="row justify-content-start g-3">
            {!isFetching 
              ? contributors.length > 0
                ? contributors.map((contributor, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <TopContributor rank={index + 1} contributor={contributor} />
                    </div>
                  ))
                : <NoData />
              : <div className="d-flex justify-content-center mt-5"><LoadingScreen /></div>
            }
          </div>
        </section>
      </div>
    // </motion.main>
  )
}