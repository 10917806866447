
import { Metadata, RecommendedArticles,RecentlySaved, FavoriteArticles, RecommendedBuddies, RecommendedTopics, CardCategory, CardArticleDescBottom, CardArticleDescTop, CardArticleHorizontal, TopContributor, SearchBar, Paginate } from "../components";
import * as articlesData from "../data/articles";
import { useState, useEffect } from "react";

import "./style.css";

export function NewsList() {
  const [news, setNews] = useState([]);
  
  const queryParameters = new URLSearchParams(window.location.search);
  const [search, setSearch] = useState(queryParameters.get("search"));

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;
  const displayUser = news.slice(pageVisited, pageVisited + usersPerPage).map((article, index) => <CardArticleHorizontal key={article.id} article={article} type="news" />);
  const pageCount = Math.ceil(news.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const getArticles = async () => {
      let queryParam;
      if (search !== null) {
        queryParam = "?search=" + search + "&category=news&limit=50";
      } else {
        queryParam = "?category=news&limit=50";
      }

      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
      }
      setNews(() => res.data);

       // console.log({ res });
    };
    getArticles();
  }, [search]);

  return (
    <div className="news pt-4">
      <Metadata page={window.location.href} isPublic />
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-7">
            <h1 className="mt-5 pt-2 pb-4">News</h1>
            <h2 className="d-none">List news</h2>
            <SearchBar placeholder="search" page="news" search={search} />

            <div className="flex flex-column gap-4 mt-4">{news.length > 0 ? displayUser : "There is no data to display"}</div>

            {/* <ReactPaginate
              breakLabel="..."
              nextLabel={">"}
              onPageChange={changePage}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel={"<"}
              renderOnZeroPageCount={null}
              containerClassName={"paginationBttns"}
              pageLinkClassName="pagee-num"
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            /> */}
            
            <Paginate changePage={changePage} pageCount={pageCount} />
            <hr />
          </div>
          <div className="d-sm-none d-md-block col-md-4 g-5">
            <RecommendedTopics />
            <RecommendedArticles />
            <FavoriteArticles />
            <RecentlySaved />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
