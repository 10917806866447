import { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom";

import Swal from "sweetalert2";

import { NoData, LoadingScreen } from "../components"
import { getFeedback } from "../data/feedback"
import AuthContext from "../components/shared/AuthContext"
import { formatDate } from "../utils/formatDate.js";

export function FeedbackPage() {
  const { user } = useContext(AuthContext)

  const [feedbackList, setFeedbackList] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const fetchData = async() => {
      const params = '?limit=1000'
      const res = await getFeedback(params)

      if (res.code !== 200) {
        setIsFetching(false)
        return Swal.fire({ title: 'Data not available...', text: 'something went wrong', icon: 'error' })
      }

      const data = res.data
      setFeedbackList(data)
      setIsFetching(false)
    }
    fetchData()
  }, [])

  
  if (user && !user.is_admin) return <Navigate to="/unauthorized"></Navigate>

  if (isFetching) return <LoadingScreen />

  return (
    <div className="container" style={{ padding: '10rem 0'}}>
      <h1 className="mb-3">Feedback from Bipi users</h1>

      <table className="table table-bordered table-hover table-responsive text-center">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Subject</th>
            <th scope="col">Submit Date</th>
            <th scope="col">From</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {feedbackList.length > 0
            ?
            feedbackList.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.subject}</td>
                <td>{formatDate(data.createdAt, 'dd MMM yyyy')}</td>
                <td>{data.createdBy}</td>
                <td>
                  <a href={`/feedback/${data.id}`}>Detail</a>
                </td>
              </tr>
            ))
            :
            <tr>
              <td colSpan={5}> <NoData /> </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}