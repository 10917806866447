
import { useState, useEffect, useMemo } from "react";
import { Metadata, RecommendedArticles, RecommendedBuddies, RecommendedTopics,  CardArticleHorizontal,  SearchBar, Paginate } from "../components";
import * as articlesData from "../data/articles";

import "./style.css";
import { useLocation  } from "react-router-dom";

export function ArticleList() {
  const location = useLocation().search;
  const queryParameters = new URLSearchParams(location);

  const [articles, setArticles] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');

  const [search, setSearch] = useState(queryParameters.get("search"));
  const [byTag, setByTag] = useState(queryParameters.get("byTag"));

  const [pageNumber, setPageNumber] = useState(0);
  const [tagName, setTagName] = useState(byTag);
  const usersPerPage = 10;
  const pageVisited = pageNumber * usersPerPage;

  const displayUser = articles?.slice(pageVisited, pageVisited + usersPerPage).map((article, index) => <CardArticleHorizontal key={article.id} article={article} type="article-list" />);

  const pageCount = Math.ceil(articles?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const sortChoices = [
    {
      value: "createdAt",
      name: "Newest",
    },
    {
      value: "views",
      name: "Popular",
    },
    {
      value: "likes",
      name: "Best",
    },
  ];
  
  useEffect(() => {
    const getArticles = async () => {
      let queryParam;
      if (search !== null && byTag === null) {
        queryParam = "?search=" + search + "&limit=50";
      } else {
        queryParam = `?limit=50&tag=${byTag}&orderBy=${orderBy}&order=desc`;
      }
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
        return setArticles(() => []);
      }

      setArticles(() => res.data);
    };

    getArticles();
  }, [search, byTag, orderBy]);

  return (
    <div className="news">
      <Metadata page={window.location.href} isPublic />
      <div className="container">
        <div className="row justify-content-between mt-5 pt-5">
          <div className="col-sm-12 col-md-7 pb-4 pt-3">
            {search !== null && <SearchBar placeholder="search" page="article" search={search} />}
            {/* <h3 className="d-flex">Searching by : {search}</h3> */}
            {byTag !== null && (
              <div className="d-flex  " style={{ borderBottom: "1px grey solid" }}>
                {byTag !== null ? <h1 className="text-capitalize">{byTag !== null ? byTag : ""}</h1> : <h1 className="pb-4">Search</h1>}

                <div className="dropdown ms-auto p-2 pt-4">
                  <select
                    className="border-none w-100"
                    onChange={function (event) {
                      setOrderBy(event.target.value);
                      setTagName(event.target.value);
                      // if (byTag !== null) {

                      //   setTimeout(() => {
                      //     // navigate("/article-list?orderBy=" + event.target.value + "&byTag=" + { tagName });
                      //   }, 1000);
                      // }
                      // // if (byTag === null) {
                      // //   // navigate("/article-list?orderBy=" + event.target.value + "&byTag=");
                      // // }
                      // // window.location.reload(false);
                    }}
                    value={orderBy}
                  >
                    {sortChoices.map((choice, index) => (
                      <option key={index} value={choice.value}>
                        {choice.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="flex flex-column gap-4 mt-4">{articles?.length > 0 ? displayUser : "Your search did not match any articles"}</div>
            {/* {articles?.length > 0 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={">"}
                onPageChange={changePage}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={"<"}
                renderOnZeroPageCount={null}
                containerClassName={"paginationBttns"}
                pageLinkClassName="pagee-num"
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            )} */}
            {articles.length > 0 && (
              <Paginate changePage={changePage} pageCount={pageCount} />
            )}
          </div>
          <div className="d-sm-none d-md-block col-md-4 ">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
