import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ImageComponent } from '../Image';
import './style.css';

import blankImg from "../../assets/images/404.svg";
import defaultImageUser from "../../assets/images/defaultUser.svg";

import { FaWalking } from "react-icons/fa";

export function CardItineraries(props) {
  const { data } = props

  return (
    <>
      {/* <div className="card-itineraries--container" style={{ display: 'none' }}>
        <div className="card-itineraries--title-container">
          <div className='thumbnail-section'>
            <ImageComponent src={data?.thumbnail ? data?.thumbnail : blankImg} alt={data?.name + ' thumbnail'} />
          </div>
          <div className='title-section'>
            <div className='title-itinearies'>
              <Link to={'/itineraries/' + data?.id}>
                <h1>{data.name}</h1>
              </Link>
              <span className='title-tooltip'>{data.name}</span>
            </div>

            <Link to={'/user-articles/' + data?.user?.username}>
              <div className='sub-title-itineraries'>
                <div className='user-photo-profile'>
                  <ImageComponent src={data?.user?.traveler?.image && checkIsImage(data?.user?.traveler?.image) ? data?.user?.traveler?.image : defaultImageUser} alt={data?.user?.username ?? 'Photo Profile'} />
                </div>
                <span className='bold'>{data?.user?.username ?? 'admin'}</span>
              </div>
            </Link>
          </div>
        </div>

        <div className='card-itineraries-detail--container'>
          <div className='itineraries-detail'>
            <span className='title'>Destination</span>
            <span>{data?.destinationCountry ? data?.destinationCountry : 'No Destination'}</span>
          </div>
          <div className='itineraries-detail'>
            <span className='title'>Date</span>
            <span>{`${formatDate(data?.startDate ?? new Date(), 'dd/MM/yyyy')} - ${formatDate(data?.endDate ?? new Date(), 'dd/MM/yyyy')}`}</span>
          </div>
          <div className='itineraries-detail'>
            <span className='title'>Days</span>
            <span>{data?.itineraryDuration?.days ?? '0'}</span>
          </div>
          <div className='itineraries-detail'>
            <span className='title'>Total Trip</span>
            <span>{data?.itineraryTrip.length ?? 0}</span>
          </div>
        </div>
      </div> */}

      <div className='card-example-1'>
        <Link to={`/itineraries/${data.id}`}>
          <>
            <div className='card-img--container'>
              <ImageComponent src={data?.thumbnail ? data?.thumbnail : blankImg} alt={data?.name + ' thumbnail'} />
              <span className='trips'><FaWalking style={{ color: '#F2B274' }} /> {data?.itineraryTrip.length ?? 0}</span>
            </div>

            <div className='card-content--container'>
              <div className='title-container'>
                <h1 className='title-text'>{data.name}</h1>
                <span className='title-tooltip'>{data.name}</span>
              </div>

              <span className='destination-text'>{data?.destinationCountry ? data?.destinationCountry : 'No Destination'}</span>
            </div>
          </>
        </Link>
      </div>
    </>
  )
}