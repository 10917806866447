import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function register(data) {
  try {
    const user = await axios.post(`auth/register`, data).then((response) => {
      return response.data;
    });
    return user;
  } catch (error) {
     // console.log(error.response.data);
    return error.response.data;
  }
}

export async function forgetPassword(data) {
  try {
    const reset = await axios.post('auth/forget-password', data);
    return reset;
  } catch (error) {
    return error.response.data
  }
}

export async function resetPassword(data, token) {
  try {
    const response = await axios.post('auth/reset-password/' + token, data);
    return response;
  } catch (error) {
    return error.response.data
  }
}

export async function isTokenValid(token) {
  try {
    const response = await axios.post('auth/check-token-validity/' + token);
    return response;
  } catch (error) {
    return error.response.data
  }
}