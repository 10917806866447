import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import * as auth from './../data/auth'
import Swal from 'sweetalert2';
import { Metadata, LoadingScreen } from './../components'

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;

  display: flex;
  justify-content: center;
  margin: 11rem 0.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 5rem 1rem 1rem;
`;

const ButtonSubmit = styled.button`
  background: black;
  color: white;
  font-weight: 500;
  font-size: 1em;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 8px;
  width: 25%;
  text-align: center;

  :hover {
    background white;
    color: black;
  }
`;

export function ResetPassword() {
  const params = useParams();

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isSubmit, setIsSubmit] = useState(false);

  const [isTokenValid, setTokenIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const resetPassword = async(e) => {
    e.preventDefault()
    setIsSubmit(true)
    await auth.resetPassword({
      newPassword,
      confirmNewPassword
    }, params.resetToken)
    .then((response) => {
      setIsSubmit(false)
      if (response?.status === 200) {
        Swal.fire({
          title: response?.data?.message,
          icon: 'success',
          text: 'Your password changed',
          confirmButtonText: 'Okay',
          confirmButtonColor: '#000'
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.href = "/";
          }
        });
        return;
      }

      Swal.fire({
        title: 'Something Happened',
        icon: 'error',
        text: response?.data?.message,
        confirmButtonText: 'Okay',
        confirmButtonColor: '#000'
      })
    })
    .catch((err) => {
      setIsSubmit(false)
      Swal.fire({
        title: 'Request Reset Password Failed',
        text: 'Oopss' + err,
        icon: 'error',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#d33'
      })
    })

  }

  const checkTokenValidation = async() => {
    const token = params.resetToken
    const req = await auth.isTokenValid(token);

    const isValid = req?.data

    if (!isValid) setTokenIsValid(false)

    setIsLoading(false)
  }

  useEffect(() => {
    checkTokenValidation()
  }, [])

  if (isLoading) return <div className='d-flex justify-content-center align-items-center' style={{ padding: '20rem 0' }}><LoadingScreen /></div>

  if (!isTokenValid) return(
    <div className="container">
      <Container>
        <div className='row d-flex gap-3 justify-content-center'>
          <h4>Page is not found...</h4>
        </div>
      </Container>
    </div>
  )

  return(
    <div className="container">
      <Metadata page={window.location.href} titlePage={`Reset Password • Bipi World`} />

      <Container>
        <Form action="POST" onSubmit={resetPassword}>
          <div className='row d-flex gap-3 justify-content-center'>
            <h4>Reset Your Password</h4>

            <div className='col-sm-12'>
              <input type="password" className='form-control' placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} required />
            </div>

            <div className='col-sm-12'>
              <input type="password" className='form-control' placeholder="Confirm New Password" onChange={(e) => setConfirmNewPassword(e.target.value)} required />
            </div>
            
            <ButtonSubmit type="submit" disabled={isSubmit}>Reset Password</ButtonSubmit>
          </div>
        </Form>
      </Container>
    </div>
  )
}