/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import * as articlesData from "../data/articles";

import {
  HighlightNews,
  RecommendedArticles,
  RecommendedBuddies,
  RecommendedTopics,
  RecentlySaved,
  CardArticleHorizontal,
  SearchBar,
  Metadata,
  NoData,
  LoadingScreen
} from "../components";

import "./style.css";

export function News() {
  const [news, setNews] = useState([]);
  const [isFetching, setIsFetching] = useState(true)
  
  useEffect(() => {
    const getNews = async () => {
      const queryParam = "?limit=100&&category=news";
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
        setIsFetching(false)
        return;
      }
      setNews(() => res.data);
      setIsFetching(false)
    };

    getNews();
  }, []);

  return (
    <div className="news mt-3" style={{  marginBottom: '5rem' }}>
      <Metadata page={window.location.href} isPublic />
      <div className="container">
        <div className="row mt-5 pt-5 justify-content-between">
          <div className="col-sm-12 col-md-8 col-lg-7">
            <h1 className=" pb-3">News</h1>
            <SearchBar placeholder="search" page="news" />

            <section id="popular-articles">
              <div className="section-header mb-5 me-0 pe-0"></div>
              {/* big content */}
              {!isFetching 
                ? news.length 
                  ? news.slice(0, 1).map((news, index) => <HighlightNews news={news} key={index} />) 
                  : <NoData />
                : <div className="d-flex justify-content-center"><LoadingScreen /></div>
              }
            </section>

          <h2 className="mb-4">Headlines</h2>
          <div className="flex flex-column gap-4">
            {!isFetching 
              ? news.slice(1, 6).length 
                ? news.slice(1, 6).map((article, index) => <CardArticleHorizontal key={index} article={article} type="news" />) 
                : <NoData />
              : <div className="d-flex justify-content-center"><LoadingScreen /></div>
            }
          </div>

          <hr />
          
          <div className={`${news.slice(1, 6).length > 5 ? 'd-flex' : 'd-none'} justify-content-center pt-5 pb-5`}>
          <Link to={`/news-list`}>
            <button className="btn btn-outline-dark py-1 px-3">Show More</button>
          </Link>
          </div>
          </div>
          <div className="col-md-3 mt-0">
            <RecommendedTopics />
            <RecommendedArticles />
            <RecentlySaved />
            <RecommendedBuddies context="Recommended Buddies" />
          </div>
        </div>
      </div>
    </div>
  );
}
