
import { useContext, useState } from 'react'

import Swal from 'sweetalert2'

import { Button } from '../Button'
import './styles.css'

import { create } from '../../data/feedback'
import AuthContext from '../shared/AuthContext'

export function FeedbackForm() {
  const { user } = useContext(AuthContext)

  const [form, setForm] = useState(null)

  const [isSubmit, setIsSubmit] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    });
  }

  const handleSubmit = async(e) => {
    e.preventDefault()

    setIsSubmit(true)
    
    const { subject, description } = form

    if (!subject || !description) {
      setIsSubmit(false)
      return Swal.fire({ title: 'Complete Form !', text: 'fill the required form', icon: 'warning' })
    }

    const payload = {
      subject,
      description,
      email: user.email
    }

    const res = await create(payload)

    if (res.code !== 201) {
      setIsSubmit(false)
      return Swal.fire({ title: 'Something went wrong', text: '', icon: 'error' })
    } 

    window.location.reload()
    // setIsSubmit(false)
  }

  return(
    <div className='container' style={{ padding: '7rem 1rem 10rem'}}>
      <h1 className='text-center mb-4'>Submit your feedback</h1>

      <form onSubmit={handleSubmit} className='d-flex flex-column'>
        <div className='form-group'>
          <label htmlFor="subject">Subject</label>
          <input type="text" name='subject' id='subject' className="form-control mt-2" onChange={handleInputChange} required />
        </div>

        <div className="form-group mt-3">
          <label for="desc">Description</label>
          <textarea className="form-control mt-2" name='description' id="desc" rows="5" onChange={handleInputChange} required></textarea>
        </div>

        <div className='form-group mt-3'>
          <label htmlFor="attachment">Screenshot / Attachment</label>
          <input type="text" name='attachment' id='attachment' className="form-control mt-2" required />
        </div>

        {/* <div className='form-group mt-3'>
          <label htmlFor="email">Your email</label>
          <input type="email" name='email' id='email' className="form-control mt-2" onChange={handleInputChange} required />
        </div> */}

        <Button type={'submit'} disabled={isSubmit} text={isSubmit ? `Loading...` : `Submit`} colorBg='black' objectStyle={{ marginTop: '1rem', padding: '0.4rem 1rem', width: 'fit-content', alignSelf: 'end', cursor: 'pointer' }} />
      </form>
    </div>
  )
}