import axios from "../plugins/axios";
import { setHeaderToken } from "../utils/auth";

export async function getFeedback(query) {
  try {
    setHeaderToken()
    const response = await axios.get(`feedback` + query);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function findOne(id) {
  try {
    setHeaderToken()
    const response = await axios.get(`feedback/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function create(payload) {
  try {
    const response = await axios.post(`feedback`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function remove(id) {
  try {
    setHeaderToken()
    const response = await axios.delete(`feedback/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}