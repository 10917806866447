/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Metadata,  CardArticleDescBottom, Button, CardArticleDescTop, CardArticleHorizontal, HighlightNews, TopContributor, NoData, PopupCampaign, LoadingScreen } from "../components";
import "./style.css";
import * as articlesData from "../data/articles";
import globe from "../assets/images/globe.svg";
import map from "../assets/images/map.svg";
import blankImg from "../assets/images/5.svg";

import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

import AuthContext from "../components/shared/AuthContext";
import Jumbotron from '../assets/images/jumbotron.webp'
import Jumbotron2 from '../assets/images/jumbotron2.webp'

export function Home() {
  const navigate = useNavigate();
  const [articlesTraveler, setArticlesTraveler] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectCountry, setSelectCountry] = useState();
  const [isFetchingArticles, setIsFetchingArticles] = useState(true);

  const [contributors, setContributors] = useState([]);
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const getContributors = async () => {
      const queryParam = "";
      const res = await articlesData.getContributors(queryParam);

      if (res.code !== 200) {
      }

      let filteredContributors = res.data;
      if(res.data.length > 0) {
        filteredContributors = res.data.filter(contributor => contributor.articles.length > 0);
      }
      setContributors(filteredContributors);

    };

    getContributors();
  }, []);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      const queryParam = "?limit=240&onlyHaveActiveArticle=true";
      const res = await articlesData.getCountries(queryParam);
      if (res.code !== 200) {
      }
      setCountries(() => res.data);
    };

    getCountries();
  }, []);

  const [cities, setCities] = useState([]);
  useEffect(() => {
    const selectedCountry = selectCountry;
    const getCities = async () => {
      const queryParam =
        "?countryId=" +
        selectedCountry +
        "&limit=6000&onlyHaveActiveArticle=true";
      const res = await articlesData.getCities(queryParam);

      if (res.code !== 200) {
        return setCities([]);
      }

      setCities(() => res.data);
    };

    selectedCountry && getCities();
  }, [selectCountry]);

  useEffect(() => {
    const getArticles = async () => {
      const filterByCountry = selectCountry ? `&countryId=${selectCountry}` : "";

      const queryParam = `?limit=-1&createdByGroup=admin${filterByCountry}`;
      const res = await articlesData.getArticles(queryParam);
      if (res.code !== 200) {
        setArticles(() => []);
        setIsFetchingArticles(false)
      }
      setArticles(() => res.data);

      const queryParamTraveler = `?limit=-1&createdByGroup=traveler${filterByCountry}`;
      const res2 = await articlesData.getArticles(queryParamTraveler);
      if (res2.code !== 200) {
        setArticlesTraveler(() => []);
        setIsFetchingArticles(false)
      }
      setArticlesTraveler(() => res2.data);
      setIsFetchingArticles(false)
    };

    getArticles();
  }, [selectCountry]);

  const [news, setNews] = useState([]);
  useEffect(() => {
    const getNews = async () => {
      const queryParam = "?limit=100&&category=news";
      const res = await articlesData.getArticles(queryParam);

      if (res.code !== 200) {
      }
      setNews(() => res.data);
    };

    getNews();
  }, []);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getCategories = async () => {
      const queryParam = "?limit=8&onlyHaveActiveArticle=true";
      const res = await articlesData.getCategories(queryParam);

      if (res.code !== 200) {
      }
      setCategories(() => res.data);
    };

    getCategories();
  }, []);

  // filter
  const [selectCity, setSelectCity] = useState();
  const [dataSearch, setDataSearch] = useState([]);
  const [articlesLength, setArticlesLength] = useState([]);
  const [mainArticlesLength, setMainArticlesLength] = useState([]);
  function addCategory(category) {
    setDataSearch([dataSearch[0], dataSearch[1], category]);
  }

  const [categoriesImage, setCategoriesImage] = useState([]);
  useEffect(() => {
    const getCategoriesImages = async () => {
      const queryParam = "?limit=8&country=" + dataSearch[0];
      const res = await articlesData.getCategories(queryParam);
      if (res.code !== 200) {
      }
      setCategoriesImage(() => res.data);
    };
    if (dataSearch[0]) {
      getCategoriesImages(dataSearch[0]);
    }
  }, [dataSearch[0]]);

  let filterSubArticle;
  let filterMainArticle;
  if (dataSearch[0] !== undefined && dataSearch[1] !== undefined && dataSearch[2] !== undefined) {
    filterSubArticle = (articles) => articles.isSubArticle === true && articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1] && articles?.category?.name === dataSearch[2];
    filterMainArticle = (articles) => articles.isSubArticle === false && articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1] && articles?.category?.name === dataSearch[2];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] !== undefined && dataSearch[2] === undefined) {
    filterSubArticle = (articles) => articles.isSubArticle === true && articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1];
    filterMainArticle = (articles) => articles.isSubArticle === false && articles?.country?.name === dataSearch[0] && articles?.city?.name === dataSearch[1];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] === undefined && dataSearch[2] === undefined) {
    filterSubArticle = (articles) => articles.isSubArticle === true && articles?.country?.name === dataSearch[0];
    filterMainArticle = (articles) => articles.isSubArticle === false && articles?.country?.name === dataSearch[0];
  }
  if (dataSearch[0] === undefined && dataSearch[2] !== undefined) {
    filterSubArticle = (articles) => articles.isSubArticle === true && articles?.category?.name === dataSearch[2];
    filterMainArticle = (articles) => articles.isSubArticle === false && articles?.category?.name === dataSearch[2];
  }
  if (dataSearch[0] !== undefined && dataSearch[1] === undefined && dataSearch[2] !== undefined) {
    filterSubArticle = (articles) => articles.isSubArticle === true && articles?.country?.name === dataSearch[0] && articles?.category?.name === dataSearch[2];
    filterMainArticle = (articles) => articles.isSubArticle === false && articles?.country?.name === dataSearch[0] && articles?.category?.name === dataSearch[2];
  }

  if (articles.length !== 0 && filterSubArticle !== undefined) {
    articles.filter(filterSubArticle).map((article, index) => articlesLength.push(article));
  }
  if (articles.length !== 0 && filterMainArticle !== undefined) {
    articles.filter(filterMainArticle).map((article, index) => mainArticlesLength.push(article));
  }

  const [isPopupOpen, setPopupOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [animationVal, setAnimationVal] = useState({
    opacity: 1,
    x_initial: 0,
    x_animate: 0,
    x_exit: 0,
    duration: 0,
  })

  function currentDayDiff(comparedDate, isMinute = false) {
    const lastShownDate = new Date(comparedDate);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - lastShownDate.getTime();

    let daysDiff;
    if (isMinute) {
      daysDiff = Math.floor(timeDiff / (60 * 1000)); // 1 minute
    } else {
      daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24)); // 1 week
    }
    return daysDiff;
  }

  useEffect(() => {
    // activate animation on mobile view...
    if(isMobile) {
      setAnimationVal({
        opacity: 1,
        x_initial: 100,
        x_animate: 0,
        x_exit: -100,
        x_duration: 0.1
      })
    }

    let shouldShowPopup = false;
    const isFirstPopupShow = localStorage.getItem("popupLastShown");

    // first condition (first time visit/open homepage)
    if (!user && !isFirstPopupShow) {
      shouldShowPopup = true;
      localStorage.setItem('popupLastShown', new Date().toISOString());
    }

    // second condition (when no user logged but user have been visit the homepage before)
    if (!user && isFirstPopupShow) {
      const dayDiff = currentDayDiff(isFirstPopupShow);
      if(dayDiff >= 7) {
        shouldShowPopup = true;
        localStorage.setItem('popupLastShown', new Date().toISOString());
      }
    }

    if (shouldShowPopup) {
      setPopupOpen(true);
    }

  }, [isMobile, user])

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    // <motion.main 
    // initial={{ opacity: animationVal.opacity, x: animationVal.x_initial }} // Mulai dari luar layar sebelah kiri
    // animate={{ opacity: animationVal.opacity, x: animationVal.x_animate }} // Animasi masuk ke posisi normal
    // exit={{ opacity: animationVal.opacity, x: animationVal.x_exit }} // Animasi keluar ke luar layar sebelah kanan
    // transition={{ duration: animationVal.duration }} // Durasi animasi
    // >
      <div className="home">
        <Metadata page={window.location.href} isPublic />
        {/* banner start */}
        <section id="intro">
          <div className="jumbotron jumbotron-fluid" style={{ backgroundImage: `url(${isMobile ? Jumbotron : Jumbotron2})`}}>
            <div className="container">
              <h1 className="display-4">Your Virtual Travel Buddy</h1>
              <h2 className="d-none">Bipi Articles</h2>
            </div>
          </div>
        </section>
        {/* banner ends */}
        <div className="container d-flex flex-column" style={{ gap: "2rem", marginBottom: "5rem" }}>
          {/* link panel */}
          <div className="row justify-content-center">
            <div className="col-lg-6 col-11 info-panel">
              {/* <form onSubmit={addSearch} className="row d-flex flexx-row"> */}
              <div className="row option">
                <div className="col p-3 d-flex align-items-center gap-3 w-100 border-end">
                  <img src={globe} alt="globe" />
                  <div className="w-100">
                    <span>Country</span>
                    <select
                      className="select-option"
                      onChange={(event) => {
                        const index = event.target.selectedIndex;
                        if (event.target[index].innerText === "All Country") {
                          setDataSearch([]);
                          setSelectCountry();
                          setCities([]);
                        } else {
                          setDataSearch([event.target[index].innerText]);
                          setSelectCountry(event.target.value);
                        }
                      }}
                    >
                      <option className="list-option" value="">
                        All Country
                      </option>
                      {countries.length
                        ? countries
                            // .filter((country) => country._count.articles > 0)
                            .map((countries) => (
                              <option className="list-option" key={countries.id} value={countries.id}>
                                {countries.name}
                              </option>
                            ))
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col p-3 d-flex align-items-center gap-3 w-100">
                  <img src={map} alt="map" />
                  <div className="w-100">
                    <span>City</span>
                    <select
                      className="select-option"
                      onChange={function (event) {
                        if (dataSearch[0] === undefined) return;
                        
                        const index = event.target.selectedIndex;
                        if (event.target[index].innerText === "All City") {
                          setSelectCity();
                          if(dataSearch[0]) {
                            setDataSearch([dataSearch[0]])
                          }
                        } else {
                          const sCity = event.target[index].innerText;
                          setDataSearch([dataSearch[0], sCity]);
                          setSelectCity(event.target.value);
                        }
                      }}
                    >
                      <option className="list-option" value="">All City</option>
                      {cities.map((city) => (
                          <option className="list-option" key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className={`container section-category ${categories.length > 0 ? 'd-block' : 'd-none'}`}>
            <div className="scroll-homepage scroll-content">
              {categories.length > 0
                ? categories.map((category, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        addCategory(category.name);
                      }}
                      className="category"
                      type="button">
                      <img src={category?.thumbnail ? category.thumbnail : blankImg} alt={category.name} />
                      <h5 className="text-center">{category.name}</h5>
                    </div>
                  ))
                : (<div>No Categories Available...</div>)}
            </div>
          </div>

          <section id="article-card ">
            <div className="section-header d-flex flex-sm-column flex-md-row justify-content-between align-items-center ">
              <h3 className="section-title pe-3">Articles </h3>
              <div className="scroll">
                <div className="d-flex">
                  {dataSearch.map((data) => (
                    <Button key={data} className="btn btn-outline-dark" text={data} onClick={() => navigate(`/article-list?search=${data}`)}></Button>
                  ))}
                </div>
              </div>
            </div>
            <hr />
            {!dataSearch.length > 0  ? (
              <div className="media-scroller">
                <div className={`${!isFetchingArticles
                  ? `${articles.filter((articles) => articles.isSubArticle === true).length > 0 
                    ? 'd-flex gap-3' : 'w-100'}` 
                  : 'w-100 d-flex justify-content-center p-5'}`}>
                  {!isFetchingArticles 
                  ? articles.filter((articles) => articles.isSubArticle === true).length > 0
                    ? articles
                        .filter((articles) => articles.isSubArticle === true)
                        .slice(0, 10)
                        .map((article, index) => <CardArticleDescBottom key={index} article={article} />)
                    : <NoData /> 
                  : <LoadingScreen />}

                  {!isFetchingArticles && articles.filter((articles) => articles.isSubArticle === true).length >= 10
                    ? <span className="sub-article ms-2 me-2 show-all-btn">
                        <a href="/articles" className="text-decoration-none">
                          <div className="rounded-circle ms-2">
                            <i className="fa-md-xl fa-solid fa-arrow-right"></i>
                          </div>
                          <p className="fw-normal text-nowrap d-none d-md-block mt-2">Show All</p>
                        </a>
                      </span>
                    : "" }
                </div>
              </div>
            ) : articlesLength.filter(filterSubArticle).length <= 10 ? (
              <div className="media-scroller">
                <div className={`${!isFetchingArticles 
                  ? `${articles.filter(filterSubArticle).length > 0 ? 'd-flex gap-3' : 'w-100'}` 
                  : 'w-100 d-flex justify-content-center p-5' }`}>
                  {!isFetchingArticles 
                  ? articles.filter(filterSubArticle).length > 0
                    ? articles
                        .filter(filterSubArticle)
                        .slice(0, 10)
                        .map((article, index) => <CardArticleDescBottom key={index} article={article} index={index} />)
                    : <NoData /> 
                  : <LoadingScreen />}

                  {!isFetchingArticles && articles.filter(filterSubArticle).length >= 10
                    ? <span className="sub-article ms-4 show-all-btn">
                        <a href={`/articles?country=${selectCountry}&city=${selectCity}&category=${dataSearch[2]}`} className="text-decoration-none">
                          <div className="rounded-circle ms-2">
                            <i className="fa-md-xl fa-solid fa-arrow-right"></i>
                          </div>
                          <p className="fw-normal text-nowrap mt-2">Show All</p>
                        </a>
                      </span>
                    : ""}
                </div>
              </div>
            ) : (
              <div>
                <div className="media-scroller">
                  <div className={`${!isFetchingArticles 
                    ? `${articles.filter(filterSubArticle).length > 0 ? 'd-flex gap-3' : 'w-100'}` 
                    : 'w-100 d-flex justify-content-center p-5'}`}>
                    {!isFetchingArticles 
                    ? articles.filter(filterSubArticle).length > 0
                      ? articles
                          .filter(filterSubArticle)
                          .slice(0, 10)
                          .map((article, index) => <CardArticleDescBottom key={index} article={article} index={index} />)
                      : <NoData />
                    : <LoadingScreen />}
                  </div>
                </div>
              </div>
            )}
          </section>

          <section id="article-card">
            <div className="section-header">
              <h3 className="section-title pb-3">More To Explore</h3>
            </div>
            {!dataSearch.length > 0 ? (
              <div className="media-scroller">
                <div className={`${!isFetchingArticles 
                  ? `${articles.filter((article) => article.isSubArticle === false && article.category.name !== "News").length > 0 ? 'd-flex gap-3' : 'w-100'}` 
                  : 'w-100 d-flex justify-content-center p-5'}`}>
                  {!isFetchingArticles 
                  ? articles.filter((article) => article.isSubArticle === false && article.category.name !== "News").length > 0
                    ? articles
                        .filter((article) => article.isSubArticle === false && article.category.name !== "News")
                        .slice(0, 10)
                        .map((article, index) => <CardArticleDescTop key={index} article={article} />)
                    : <NoData />
                  : <LoadingScreen />}

                  {!isFetchingArticles && articles.filter((article) => article.isSubArticle === false && article.category.name !== "News").length >= 10
                    ? <span className="main-article ms-2 me-2 show-all-btn">
                        <a href="/articles" className="text-decoration-none">
                          <div className="rounded-circle ms-2">
                            <i className="fa-md-xl fa-solid fa-arrow-right"></i>
                          </div>
                          <p className="fw-normal d-none d-md-block text-nowrap mt-2">Show All</p>
                        </a>
                      </span>
                    : ""}
                </div>
              </div>
            ) : articles.filter(filterMainArticle).length <= 10 ? (
              <div className="media-scroller">
                <div className={`${!isFetchingArticles 
                  ? `${articles.filter(filterMainArticle).length > 0 ? 'd-flex gap-3' : 'w-100'}` 
                  : 'w-100 d-flex justify-content-center p-5'}`}>
                  {!isFetchingArticles 
                  ? articles.filter(filterMainArticle).length > 0
                    ? articles
                        .filter(filterMainArticle)
                        .slice(0, 10)
                        .map((article, index) => <CardArticleDescTop key={index} article={article} />)
                    : <NoData /> 
                  : <LoadingScreen />}

                  {!isFetchingArticles && articles.filter(filterMainArticle).length >= 10 
                    ? <span className="main-article ms-4 show-all-btn">
                        <a href={`/articles?country=${selectCountry}&city=${selectCity}&category=${dataSearch[2]}`} className="text-decoration-none">
                          <div className="rounded-circle ms-2">
                            <i className="fa-md-xl fa-solid fa-arrow-right"></i>
                          </div>
                          <p className="fw-normal text-nowrap mt-2">Show All</p>
                        </a>
                      </span>
                    : ""}
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="media-scroller">
                  <div className={`${!isFetchingArticles 
                    ? `${articles.filter(filterMainArticle).length > 0 ? 'd-flex gap-3' : 'w-100'}` 
                    : 'w-100 d-flex justify-content-center p-5'}`}>
                    {!isFetchingArticles 
                    ? articles.filter(filterMainArticle).length > 0
                      ? articles
                          .filter(filterMainArticle)
                          .slice(0, 10)
                          .map((article, index) => <CardArticleDescTop key={index} article={article} />)
                      : <NoData />
                    : <LoadingScreen />}
                  </div>
                </div>
              </div>
            )}
          </section>

          <section id="popular-articles" className={news.length > 0 ? 'd-block' : 'd-none'}>
            <div className="section-header mb-4">
              <h3 className="section-title">News</h3>
            </div>
            <div className="row justify-content-between">
              {/* big content */}
              <div className="col-sm-12 col-lg-6 col-md-6 d-flex flex-column gap-2">{news.length > 0 ? news.slice(0, 1).map((news, index) => <HighlightNews news={news} key={index} />) : <NoData />}</div>

              {/* end big content */}
              <div className="col-sm-12 col-lg-6 col-md-6 d-flex flex-column">{news.length > 0 ? news.slice(1, 5).map((article, index) => <CardArticleHorizontal key={index} article={article} type="news-home" />) 
              : <NoData />}</div>
            </div>
          </section>

          <section id="top-contributors" className={isMobile ? 'd-none' : contributors.length > 0 ? 'd-block' : 'd-none'}>
            <div className="section-header mb-4">
              <h3 className="section-title">Top Contributors</h3>
            </div>
            <div className="row justify-content-start g-3">
              {contributors.length > 0
                ? contributors.map((contributor, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <TopContributor rank={index + 1} contributor={contributor} />
                    </div>
                  ))
                : <NoData />}
            </div>
          </section>

          <section id="popular-articles" className={articles.length > 0 ? 'd-block' : 'd-none'}>
            <div className="section-header mb-4">
              <h3 className="section-title">Popular Articles from Bipi</h3>
            </div>
            <div className="row justify-content-between g-3">
              {articles.length > 0
                ? articles.slice(0, 6).map((article, index) => (
                    <div key={index} className="col-sm-12 col-md-6">
                      <CardArticleHorizontal article={article} type="article" />
                    </div>
                  ))
                : <NoData />}
            </div>
          </section>

          <section id="popular-articles" className={articlesTraveler.length > 0 ? 'd-block' : 'd-none'}>
            <div className="section-header mb-4">
              <h3 className="section-title">Popular Articles from Users</h3>
            </div>
            <div className="row justify-content-between g-3">
              {articlesTraveler.length > 0
                ? articlesTraveler.slice(0, 6).map((article, index) => (
                    <div className="col-sm-12 col-md-6" key={index}>
                      <CardArticleHorizontal article={article} type="article" />
                    </div>
                  ))
                : <NoData />}
            </div>
          </section>
        </div>

        <PopupCampaign show={isPopupOpen} handleClose={handleClosePopup} />
      </div>
    // </motion.main>
  );
}
